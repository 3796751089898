<script>
import Layout from "../../layouts/main"
import appConfig from "@/app.config"
import StoreService from '@/services/store'
import defaultStoreImage from '@/assets/images/default-store.png'

export default {
  page: {
    title: "Lojas",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: { Layout },
  data() {
    return {
      title: "Lojas",
      loading: false,
      showModal: false,
      isModalError: false,
      modalError: '',
      modalLoading: false,
      stores: null,
      storeEmail: '',
      storeName: '',
      storeToRemove: '',
      storeToApprove: '',
      storeSelected: {
        address: {},
      },
      defaultStoreImage
    };
  },
  mounted() {
    this.loadStores();
  },
  methods: {
    loadStores(showLoading = true) {
      if (showLoading) {
        this.loading = true;
      }

      StoreService.getAllStores().then(res => {
        this.stores = res.data;
        this.loading = false;
      }).catch(() => {
        this.loading = false;
      })
    },
    createStore() {
      this.modalLoading = true;
      this.isModalError = false

      StoreService.createStore({ name: this.storeName, email: this.storeEmail }).then(() => {
        this.modalLoading = false;
        this.showModal = false;

        this.storeName = '';
        this.storeEmail = '';

        this.$toast.open('Nova loja cadastrada com sucesso');
        this.loadStores(false);
      }).catch((err) => {
        if (err.response.data.code && err.response.data.code === 'E9999') {
          this.modalError = err.response.data.msg;
        } else {
          this.modalError = 'Ocorreu um erro ao executar essa ação';
        }
        
        this.modalLoading = false;
        this.isModalError = true;
      });
    },
    removeStore() {
      this.$bvModal.hide('modal-remove');      

      StoreService.removeStore(this.storeToRemove._id).then(() => {
        this.loadStores(false);
        this.$toast.open('Loja excluída com sucesso');
      }).catch(() => {
        this.$toast.error('Ocorreu um erro ao excluir a loja. Tente novamente.');
      })
    },
    approveStore() {
      this.$bvModal.hide('modal-approve');      

      StoreService.approveStore(this.storeToApprove._id).then(() => {
        this.loadStores(false);
        this.$toast.open('Cadastro aprovado com sucesso');
      }).catch(() => {
        this.$toast.error('Ocorreu um erro ao aprovar o cadastro. Tente novamente.');
      })
    },
    activateStore(id) {
      StoreService.activateStore(id).then(() => {
        this.loadStores(false);
        this.$toast.open('Loja ativada com sucesso');
      }).catch(() => {
        this.$toast.error('Ocorreu um erro ao ativar a loja. Tente novamente.');
      })
    },
    deactivateStore(id) {
      StoreService.deactivateStore(id).then(() => {
        this.loadStores(false);
        this.$toast.open('Loja desativada com sucesso');
      }).catch(() => {
        this.$toast.error('Ocorreu um erro ao desativar a loja. Tente novamente.');
      })
    },
    seeData(data) {
      this.storeSelected = data;
      this.$bvModal.show('modal-store');
    },
    seeProducts(storeId) {
      this.$router.push(`/products-store/${storeId}`);
    }
  }
};
</script>

<template>
  <Layout>
    <div class="row">
      <div class="col-12">
        <div class="page-title-box d-flex align-items-center justify-content-between">
          <h4 class="mb-0 font-size-18">{{title}}</h4>
        </div>
      </div>
    </div>

    <div v-if="stores">
      <!-- <div class="row">
        <div class="col-4">
        </div>
        <div class="col-8">
          <div class="text-sm-right">
            <button type="button" class="btn btn-primary btn-rounded mb-2 mr-2" @click="showModal = true">
              <i class="mdi mdi-plus mr-1"></i> Cadastrar Nova Loja
            </button>
          </div>
        </div>
      </div> -->

      <div class="row" v-if="stores.length > 0">
        <div class="col-lg-12">
          <div class="table-responsive">
            <table class="table project-list-table table-nowrap table-centered table-borderless">
              <thead>
                <tr>
                  <th scope="col" style="width: 100px">#</th>
                  <th scope="col">Nome da Loja</th>
                  <th scope="col">Status</th>
                  <th scope="col">Produtos Cadastrados</th>
                  <th scope="col">Ações</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="store in stores" :key="store._id">
                  <td>
                    <img :src="`${store.image || defaultStoreImage}`" alt class="avatar-sm rounded-circle" />
                  </td>
                  <td>
                    <h5 class="text-truncate font-size-14">
                      <!-- <a href="javascript: void(0);" class="text-dark">{{store.name}}</a> -->
                      {{store.name}}
                    </h5>
                    <p class="text-muted mb-0">{{store.email}}</p>
                  </td>
                  <td>
                    <span
                      class="badge badge-pill font-size-12"
                      :class="{
                        'badge-success': store.status === 'active',
                        'badge-danger': store.status === 'inactive',
                        'badge-warning': store.status === 'awaiting' }"
                    >{{store.statusDescription}}</span>
                  </td>

                  <td>
                    <p class="mb-0">{{store.productsCount}}</p>
                  </td>
                  
                  <td>
                    <b-dropdown class="card-drop" variant="white" dropleft toggle-class="p-0">
                      <template v-slot:button-content>
                        <i class="mdi mdi-dots-horizontal font-size-18"></i>
                      </template>
                      <b-dropdown-item @click="seeData(store)">
                        <i class="fas fa-folder-open text-warning mr-2 mt-2"></i>
                        Dados Cadastrais
                      </b-dropdown-item>
                      <b-dropdown-item @click="seeProducts(store._id)">
                        <i class="fas fa-folder-open text-warning mr-2 mt-2"></i>
                        Produtos
                      </b-dropdown-item>
                      <b-dropdown-item v-b-modal.modal-approve @click="storeToApprove = store" v-if="!store.approved">
                        <i class="fas fas fa-thumbs-up text-success mr-2 mt-2"></i>
                        Aprovar Cadastro
                      </b-dropdown-item>
                      <b-dropdown-item v-b-modal.modal-remove @click="storeToRemove = store" v-if="store.canRemove">
                        <i class="fas fa-trash-alt text-danger mr-2 mt-2"></i>
                        Excluir
                      </b-dropdown-item>
                      <b-dropdown-item href="javascript: void(0);" v-if="store.active && store.canChangeStatus" @click="deactivateStore(store._id)">
                        <i class="fas fa-minus-circle text-danger mr-2 mt-2"></i>
                        Inativar
                      </b-dropdown-item>
                      <b-dropdown-item v-if="!store.active && store.canChangeStatus" @click="activateStore(store._id)">
                        <i class="fas fa-play-circle text-success mr-2 mt-2"></i>
                        Ativar
                      </b-dropdown-item>
                    </b-dropdown>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>

    <div class="row" v-if="loading">
      <div class="col-12">
        <div class="text-center my-3">
          <a href="javascript:void(0);" class="text-primary">
            <b-spinner class="m-2" variant="primary" role="status"></b-spinner>
          </a>
        </div>
      </div>
    </div>

    <b-modal
      id="modal-remove"
      title="Excluir Loja?"
      title-class="font-18"
      centered
    >
      <p>Confirma a exclusão da loja <b>{{storeToRemove.name}}</b>?</p>

      <div slot="modal-footer">
        <b-btn variant="link" @click="$bvModal.hide('modal-remove')">Cancelar</b-btn>
        <b-btn variant="primary" @click="removeStore">Excluir</b-btn>
      </div>
    </b-modal>

    <b-modal
      id="modal-approve"
      title="Aprovar Cadastro?"
      title-class="font-18"
      centered
    >
      <p>Confirma a aprovação da loja <b>{{storeToApprove.name}}</b>?</p>

      <div slot="modal-footer">
        <b-btn variant="link" @click="$bvModal.hide('modal-approve')">Cancelar</b-btn>
        <b-btn variant="primary" @click="approveStore">Aprovar</b-btn>
      </div>
    </b-modal>

    <b-modal v-model="showModal" title="Nova Loja" centered>
      <b-alert v-model="isModalError" variant="danger" class="mt-3" dismissible>{{modalError}}</b-alert>
      <form>
        <div class="form-group">
          <label for="storeName">Nome do da Loja</label>
          <input id="storeName" name="storeName" type="text" class="form-control" v-model="storeName" />
        </div>

        <div class="form-group">
          <label for="storeEmail">E-mail de Acesso</label>
          <input id="storeEmail" name="storeEmail" type="email" class="form-control" v-model="storeEmail" />
        </div>
      </form>

      <div class="row" v-if="modalLoading">
        <div class="col-12">
          <div class="text-center my-3">
            <a href="javascript:void(0);" class="text-primary">
              <b-spinner class="m-2" variant="primary" role="status"></b-spinner>
            </a>
          </div>
        </div>
      </div>
      
      <template v-slot:modal-footer>
        <b-button variant="link" @click="showModal = false">Cancelar</b-button>
        <b-button variant="primary" @click="createStore()">
          Cadastrar Loja
        </b-button>
      </template>
    </b-modal>

    <b-modal
      id="modal-store"
      size="lg"
      title="Dados da Loja"
      title-class="font-18"
    >
      <div class="row">
        <div class="col-8">
          <div class="form-group">
            <label>Nome</label>
            <input type="text" class="form-control" v-model="storeSelected.name" readonly />
          </div>
        </div>

        <div class="col-4">
          <div class="form-group">
            <label>CNPJ</label>
            <input type="text" class="form-control" v-model="storeSelected.cnpj" readonly />
          </div>
        </div>

        <div class="col-6">
          <div class="form-group">
            <label>Endereço</label>
            <input type="text" class="form-control" v-model="storeSelected.address.street" readonly />
          </div>
        </div>

        <div class="col-2">
          <div class="form-group">
            <label>Número</label>
            <input type="text" class="form-control" v-model="storeSelected.address.number" readonly />
          </div>
        </div>

        <div class="col-4">
          <div class="form-group">
            <label>Bairro</label>
            <input type="text" class="form-control" v-model="storeSelected.address.district" readonly />
          </div>
        </div>

        <div class="col-6">
          <div class="form-group">
            <label>Cidade</label>
            <input type="text" class="form-control" v-model="storeSelected.address.city" readonly />
          </div>
        </div>

        <div class="col-2">
          <div class="form-group">
            <label>UF</label>
            <input type="text" class="form-control" v-model="storeSelected.address.state" readonly />
          </div>
        </div>

        <div class="col-4">
          <div class="form-group">
            <label>CEP</label>
            <input type="text" class="form-control" v-model="storeSelected.address.zipcode" readonly />
          </div>
        </div>

        <div class="col-4">
          <div class="form-group">
            <label>E-mail</label>
            <input type="text" class="form-control" v-model="storeSelected.email" readonly />
          </div>
        </div>

        <div class="col-4">
          <div class="form-group">
            <label>Telefone</label>
            <input type="text" class="form-control" v-model="storeSelected.phoneNumber" readonly />
          </div>
        </div>

        <div class="col-4">
          <div class="form-group">
            <label>Contato</label>
            <input type="text" class="form-control" v-model="storeSelected.contactName" readonly />
          </div>
        </div>
      </div>

      <div slot="modal-footer">
        <b-btn variant="link" @click="$bvModal.hide('modal-store')">Fechar</b-btn>
      </div>
    </b-modal>
  </Layout>
</template>

<style lang="scss">
  .form-control[readonly] {
    background-color: #f1f1f1;
  }
</style>